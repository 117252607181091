const FacebookIcon = () => {
  return (
    <svg
      width="9px"
      height="17px"
      viewBox="0 0 9 17"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.58177 2.67364L8.05177 2.67364L8.05177 0.113386C7.79816 0.0784982 6.92596 0 5.91018 0C3.79073 0 2.33884 1.33313 2.33884 3.78335L2.33884 6.03832L0 6.03832L0 8.90049L2.33884 8.90049L2.33884 16.1022L5.20638 16.1022L5.20638 11.9995L5.20638 11.9995L5.20638 11.9995C5.20638 11.9995 5.20642 12.852 5.20642 12.0774C5.20642 11.3028 5.20638 8.90116 5.20638 8.90116L7.45062 8.90116L7.80688 6.03899L5.20571 6.03899L5.20571 4.06715C5.20638 3.2399 5.42912 2.67364 6.58177 2.67364L6.58177 2.67364Z"
        id="Path"
        fill="#2A4EC5"
        stroke="none"
      />
    </svg>
  );
};
export default FacebookIcon;
